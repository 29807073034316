import React, { Component } from 'react';
const smallLoader = require('../small_loader.gif');
class SmallLoader extends Component {
    render() {
        return (
            <div id='small-loader'>
                <img className="small-loader" src={smallLoader} alt='' />
            </div>
        )
    }
}

export default SmallLoader;