let mode;
if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "prod";
}

let localURL = "http://localhost:3000";
let awsUrlSelect = "https://ydflygm8md.execute-api.us-east-1.amazonaws.com/dev";

if (mode === "prod") {
  awsUrlSelect = "https://8xwqmw7xti.execute-api.us-east-1.amazonaws.com/prod";
} else if (mode === "local") {
  awsUrlSelect = localURL;
}

export let awsUrl = awsUrlSelect;
