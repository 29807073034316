import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import Dropworks from "./Dropworks";
// import DropUserProfile from "./DropUserProfile";
// import Dropresources from "./Dropresources";
// import QuoteButtons from "./QuoteButtons";
// import { openNav, closeNav, logout, startFresh } from "../../utils/common";
// import { Auth } from "aws-amplify";
// import UserIcon from "../../images/user-circle-regular.svg";


class Navbar extends Component {
    render() {
        // const pathLogin = window.location.pathname === "/Login";
        return (
            <div id="navbarMain" className="container-fluid">
                <div className="row">
                    <div className="col-sm-3">
                        <img
                            className="logoMain"
                            src={require("../new-logo.png")}
                            alt="Insure Logo"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
