import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UploadForm from "./components/UploadForm"
import Navbar from "./components/navbar"
// import Amplify from 'aws-amplify';

function App() {
  return (
    <div>
      <Navbar />
      <UploadForm />
    </div>
  );
}

export default App;
